export const statusEnum = {
  OPEN: 'EM_ABERTO',
  WAITING_CLOSE: 'AGUARDANDO_FECHAMENTO',
  PAID: 'PAGA',
  CLOSED: 'FECHADA',
};

export default function getColorStatus(status) {
  switch (status) {
    case 'PAID':
      return 'status--paga';
    case 'WAITING_CLOSE':
      return 'status--aguardando';
    default:
      return '';
  }
}

export function getStatusName(status) {
  switch (status) {
    case 'OPEN':
      return 'Em aberto';
    case 'WAITING_CLOSE':
      return 'Aguardando fechamento';
    case 'FUTURE':
      return 'Futura';
    case 'CLOSED':
      return 'Fechada';
    case 'PAID':
      return 'Paga';
    default:
      return '';
  }
}

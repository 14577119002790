<template>
  <FormContent :selected="selected" class="pa-6">
    <div class="margin-fix pa-3">
      <h3>Detalhes do Pedido</h3>
    </div>
    <div class="d-flex mt-10">
      <label class="left-content">Número do pedido</label>
      <v-text-field :disabled="true" v-model="order.number" filled dense light />
      <label class="left-content ml-10">Data de sincronização</label>
      <v-text-field :disabled="true" v-model="formatSubmittedAt" filled dense light />
    </div>
    <div class="d-flex mt-10">
      <label class="left-content">Nome do Seller</label>
      <v-text-field :disabled="true" v-model="order.sellerId" filled dense light />
    </div>
    <div class="d-flex mt-10">
      <label class="left-content"> Total do pedido</label>
      <mask-input v-model="order.total" :options="MaskOptions" :disabled="true" dense filled />
      <label class="left-content ml-10">Status atual</label>
      <v-text-field :disabled="true" v-model="formatStatusOrder" filled dense light />
    </div>
    <div class="margin-fix pa-3 subtitle" v-if="historyItens.length > 0">
      Histórico de transações
      <v-timeline align-top dense>
        <v-timeline-item v-for="(item, i) in historyItens" :key="i" :color="getColorHistory(historyItens, i)">
          <template #icon>
            <img src="@/assets/images/icons/pathStar.svg" v-if="i == 0" />
            <img src="@/assets/images/icons/pathElipse.svg" v-else-if="i == historyItens.length - 1" />
            <img src="@/assets/images/icons/pathCheckBold.svg" v-else-if="item.updatedAt" />
          </template>
          <div class="d-flex align-center">
            <div class="date-titulo  left-content">{{ item.updatedAt }}</div>
            <div class="text-caption right-content">
              <v-expansion-panels inset>
                <v-expansion-panel>
                  <v-expansion-panel-header class="custom-header" :hide-actions="item.metadata.length == 0" :disabled="item.metadata.length == 0">
                    <div class="d-flex align-center status-titulo">
                      <div class="me-auto">{{ statusOrder[item.status] || item.status }}</div>
                      <div class="" v-if="item.url.length > 0">
                        <a v-bind:href="item.url[0].value" target="_blank">
                          <v-btn outlined color="primary" class="my-0" depressed>
                            Consultar
                          </v-btn>
                        </a>
                      </div>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content v-if="item.metadata">
                    <div v-for="(content, i) in item.metadata" :key="i">
                      <div class="label-font">{{ content.label }}</div>
                      <div class="value-font">{{ content.value }}</div>
                      <div class="hr-line"></div>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </div>
        </v-timeline-item>
      </v-timeline>
    </div>
  </FormContent>
</template>

<script>
import Vue from 'vue';
import moment from 'moment';
import FormContent from '@/components/template/form/FormContent';
import { getHistoryOrder } from '@/service/order/order-service';
import { statusOrder } from '../enum/StatusOrder';
import MaskInput from '@/components/template/form/input/MaskInput';

const MaskOptions = {
  numeral: true,
  numeralDecimalScale: 2,
  numeralDecimalMark: ',',
  delimiter: '.',
  prefix: 'R$ ',
};

export default Vue.extend({
  components: {
    FormContent,
    MaskInput,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: 'Informações Gerais',
    },
    order: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    this.getHistory();
  },
  data: () => ({
    selected: true,
    colLabelHAlign: 'left',
    historyItens: [],
    statusOrder,
    MaskOptions,
  }),
  methods: {
    async getHistory() {
      try {
        const response = await getHistoryOrder(this.$route.params.number);
        this.historyItens = response;
      } catch (error) {
        console.error('Error getHistory: ', error);
      }
    },
    getColorHistory(historyItens, i) {
      if (i === 0) {
        return 'primary';
      }
      if (i === historyItens.length - 1) {
        return '#02d13f';
      }
      return '#6c757d';
    },
  },
  computed: {
    formatSubmittedAt() {
      if (this.order?.submittedAt) {
        return moment(this.order.submittedAt, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/yyyy h:mm:ss');
      }
      return '';
    },
    formatStatusOrder() {
      if (this.order?.status) {
        return statusOrder[this.order.status] || this.order.status;
      }
      return '';
    },
  },
});
</script>

<style lang="scss" scoped>
.v-timeline::before {
  top: 55px;
  height: calc(100% - 120px);
}

.subtitle {
  color: var(--grey-0);
  font-size: 18px;
}

.label-font {
  color: #190804;
  font-size: 12px;
  font-family: $font-family-medium;
}

.value-font {
  margin-bottom: 5px;
  color: var(--grey-1);
  font-size: 14px;
  font-family: $font-family-light;
}

.status-titulo {
  font-family: $font-family-medium;
  height: 20px;
  color: var(--grey-0);
  font-size: 14px;
}

.date-titulo {
  color: var(--grey-0);
  font-family: $font-family-book;
  font-size: 14px;
  padding-right: 10px;
}

.hr-line {
  border-bottom: 1px solid #00000033;
  margin: 20px 0px;
}

.v-expansion-panels--inset > .v-expansion-panel--active {
  max-width: 100%;
}

.left-content {
  width: 20%;
}

.right-content {
  flex-grow: 1;
  width: 100%;
}
</style>

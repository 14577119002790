<template>
  <FormContent :selected="selected" class="pa-6">
    <div>
      <h3>Detalhes do Frete</h3>
    </div>
    <div class="d-flex mt-10">
      <label class="left-content">Valor do frete</label>
      <mask-input v-model="freightDetails.cost" :options="MaskOptions" :disabled="true" dense filled />
      <label class="left-content ml-10">Desconto</label>
      <mask-input v-model="freightDetails.discount" :options="MaskOptions" :disabled="true" dense filled />
    </div>
    <div class="d-flex  mt-10">
      <label class="left-content">Juros</label>
      <mask-input v-model="freightDetails.tax" :options="MaskOptions" :disabled="true" dense filled />
      <label class="left-content ml-10">Valor total</label>
      <mask-input v-model="freightDetails.totalValue" :options="MaskOptions" :disabled="true" dense filled />
    </div>
  </FormContent>
</template>

<script>
import Vue from 'vue';
import FormContent from '@/components/template/form/FormContent';
import MaskInput from '@/components/template/form/input/MaskInput';

const MaskOptions = {
  numeral: true,
  numeralDecimalScale: 2,
  numeralDecimalMark: ',',
  delimiter: '.',
  prefix: 'R$ ',
};

export default Vue.extend({
  components: {
    FormContent,
    MaskInput,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: 'Frete',
    },
    freightDetails: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    selected: false,
    colLabelWidth: '200px',
    colLabelHAlign: 'left',
    MaskOptions,
  }),
});
</script>

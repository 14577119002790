var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FormContent',{staticClass:"pa-6",attrs:{"selected":_vm.selected}},[_c('div',{staticClass:"margin-fix pa-3 mb-10"},[_c('h3',[_vm._v(_vm._s(_vm.title))])]),_c('v-data-table',{staticClass:"elevation-1 custom-table mb-8",attrs:{"headers":_vm.header,"items":_vm.items,"multi-sort":"","server-items-length":_vm.items.length,"footer-props":{
      'items-length': 100,
      'items-per-page-text': 'Linhas por página',
      'items-per-page-options': [10, 20, 50],
      pageText: '{0}-{1} de {2}',
    }},scopedSlots:_vm._u([{key:"item.quantity",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',{staticClass:"itens"},[_vm._v(_vm._s(item.quantity))])]}},{key:"item.unitPrice",fn:function(ref){
    var item = ref.item;
return [_vm._v(" R$ "+_vm._s(Number(item.unitPrice).toLocaleString('pt-br', { minimumFractionDigits: 2 }))+" ")]}},{key:"item.totalPrice",fn:function(ref){
    var item = ref.item;
return [_vm._v(" R$ "+_vm._s(Number(item.totalPrice).toLocaleString('pt-br', { minimumFractionDigits: 2 }))+" ")]}},{key:"item.hasServiceToExecute",fn:function(ref){
    var item = ref.item;
return [(item.hasServiceToExecute)?_c('v-icon',{attrs:{"color":"var(--green-0)"}},[_vm._v("mdi-check")]):_c('v-icon',{attrs:{"color":"var(--red-0)"}},[_vm._v("mdi-close")])]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"pt-10 pb-10"},[_vm._v(" Nenhum resultado encontrado ")])]},proxy:true}],null,true)}),_c('div',{staticClass:"d-flex"},[_c('label',{staticClass:"left-content"},[_vm._v("Total do frete")]),_c('mask-input',{attrs:{"options":_vm.MaskOptions,"disabled":true,"dense":"","filled":""},model:{value:(_vm.freightDetails.cost),callback:function ($$v) {_vm.$set(_vm.freightDetails, "cost", $$v)},expression:"freightDetails.cost"}}),_c('label',{staticClass:"left-content ml-10"},[_vm._v("Total do pedido")]),_c('mask-input',{attrs:{"options":_vm.MaskOptions,"disabled":true,"dense":"","filled":""},model:{value:(_vm.order.total),callback:function ($$v) {_vm.$set(_vm.order, "total", $$v)},expression:"order.total"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <v-dialog v-model="show" :max-width="maxWidth">
        <v-card class="custom-modal-card">
            <template v-slot:header> </template>
            <template v-slot:content> </template>
            <template v-slot:footer> </template>

            <v-card-title class="custom-modal-card--header text--grey-1 p4">{{ title }}</v-card-title>
            <v-card-text class="custom-modal-card--content text--grey-1">
                {{ description }}
            </v-card-text>
            <v-card-actions>
                <v-btn outlined color="primary" class="ml-auto mr-2" @click="() => actionHandler && actionHandler(false)">
                    Cancelar
                </v-btn>

                <v-btn depressed color="primary" @click="() => actionHandler && actionHandler(true)">
                    Sim
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
    props: {
        title: {
            type: String,
        },
        description: {
            type: String,
        },
        show: {
            type: Boolean,
            required: false,
            default: false,
        },
        actionHandler: {
            type: Function,
        },

        maxWidth: {
            type: String || Number || undefined,
            default: '450',
        },
    },
});
</script>

<style lang="scss">
.custom-modal-card {
    padding: 1.5rem 2.8rem 1.1rem 2.8rem;

    &--header {
        margin-bottom: 2rem;
    }

    &--content {
        margin-bottom: 1rem;
    }
}
</style>

<template>
  <FormContent :selected="selected" class="pa-6">
    <v-data-table
      :headers="header"
      :items="items"
      class="elevation-1 custom-table mb-8"
      :options.sync="options"
      multi-sort
      :server-items-length="items.length"
      :hide-default-footer="true"
    >
      <template v-slot:[`item.value`]="{ item }">
        <number-mask-label class="table-color" v-model="item.value" filled />
      </template>
      <template v-slot:no-data>
        <div class="pt-10 pb-10">
          Nenhum resultado encontrado
        </div>
      </template>
    </v-data-table>

    <div class="d-flex">
      <div class="custom-font-title me-auto">Lançamentos do pedido</div>
      <div class="justify-end" v-if="isAdmin">
        <v-btn class="custom-button--light" @click="showManualInvoiceModal()" outlined>INCLUIR LANÇAMENTO MANUAL</v-btn>
      </div>
    </div>

    <v-data-table
      :headers="headerOrder"
      :items="itemsOrder"
      class="elevation-1 custom-table mt-8"
      :options.sync="optionsOrder"
      multi-sort
      :server-items-length="totalElementsOrder"
      :footer-props="{
        'items-length': 100,
        'items-per-page-text': 'Linhas por página',
        'items-per-page-options': [10, 20, 50],
        pageText: '{0}-{1} de {2}',
      }"
    >
      <template v-slot:[`item.invoiceId`]="{ item }">
        <a v-if="item.invoiceId" @click.prevent="openDetail(item.invoiceId)">{{ item.invoiceId }}</a>
      </template>
      <template v-slot:[`item.netValue`]="{ item }">
        <div :class="getColor(item.commissionValue)">{{ item.netValue }}</div>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <div class="camelcase" :class="getColorStatus(item.status)">{{ item.status }}</div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon :size="20" @click="() => navigatorToInvoice(item.invoiceId)" color="primary" v-bind="attrs" v-on="on">
              {{ 'mdi-magnify-plus-outline' }}
            </v-icon>
          </template>
          {{ 'Visualizar fatura' }}
        </v-tooltip>
      </template>
      <template v-slot:[`item.invoiceStatus`]="{ item }">
        <div class="camelcase" :class="getColorStatus(item.invoiceStatus)">{{ getStatusName(item.invoiceStatus) }}</div>
      </template>
      <template v-slot:no-data>
        <div class="pt-10 pb-10">
          Nenhum resultado encontrado
        </div>
      </template>
    </v-data-table>
    <div class="custom-font-title my-8">
      Composição do valor do pedido
    </div>
    <div class="d-flex flex-column elevation-1">
      <div class="d-flex composition-description">
        <div class="col-description">Valor total de produtos</div>
        <number-mask-label class="col-value color--grey-1" v-model="form.productValue" filled />
      </div>
      <div class="d-flex composition-description">
        <div class="col-description">Valor total de frete</div>
        <number-mask-label class="col-value color--grey-1" v-model="form.shippingValue" filled />
      </div>
      <div class="d-flex composition-description">
        <div class="col-description">Valor total de acréscimo</div>
        <number-mask-label class="col-value color--grey-1" v-model="form.interestValue" filled />
      </div>
      <div class="d-flex composition-description">
        <div class="col-description">Valor total de comissões</div>
        <number-mask-label class="col-value color--grey-1" v-model="form.comissionValue" filled />
      </div>
      <div class="d-flex composition-description">
        <div class="col-description">Valor total de estornos</div>
        <number-mask-label class="col-value color--grey-1" v-model="form.chargeBackValue" filled />
      </div>
      <div class="d-flex composition-description">
        <div class="col-description">Valor total de cancelamentos</div>
        <number-mask-label class="col-value color--grey-1" v-model="form.cancelTotal" filled />
      </div>
      <div class="d-flex composition-description">
        <div class="col-description">Valor líquido total de pagamentos</div>
        <number-mask-label class="col-value color--green-0" v-model="form.value" filled />
      </div>
      <div class="d-flex composition-description">
        <div class="col-description--total">Valor líquido final</div>
        <number-mask-label class="col-value--total" v-model="form.value" filled />
      </div>
    </div>
    <ManualInvoiceModal :showModal="showModal" :close="closeManualInvoiceModal" :cancel="cancelManualInvoiceModal" />
  </FormContent>
</template>

<script>
import Vue from 'vue';
import FormContent from '@/components/template/form/FormContent';
import ManualInvoiceModal from './ManualInvoiceModal';
import store from '@/store/index';
import getInvoice, { getInvoiceTotal } from '@/service/order/order-service';
import { routerPush } from '@/service/kplaceRouter';
import notification from '@/service/notification';
// eslint-disable-next-line import/no-named-as-default
import statusEnum, { getStatusName } from '@/views/order/enum/StatusEnum';
import NumberMaskLabel from '@/components/template/form/NumberMaskLabel';

const { isAdmin } = store?.getters?.currentUser;

export default Vue.extend({
  components: {
    FormContent,
    ManualInvoiceModal,
    NumberMaskLabel,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: 'Pagamentos',
    },
    items: {
      type: Array,
      required: true,
    },
  },
  mounted() {
    this.getInvoice();
    this.getInvoiceTotal();
  },
  watch: {
    optionsOrder: {
      handler() {
        this.getInvoice();
      },
    },
  },
  data() {
    return {
      showModal: false,
      selected: false,
      options: {},
      optionsOrder: {},
      totalElementsOrder: 0,
      itemsOrder: [],
      isAdmin,
      form: {},
      header: [
        { text: 'Método', value: 'type' },
        { text: 'Data', value: 'paidAt' },
        { text: 'Valor', value: 'value' },
        { text: 'Status', value: 'status' },
      ],
      headerOrder: [
        { text: 'Pagto.', value: 'dateAndHour' },
        { text: 'Fatura', value: 'invoiceId' },
        { text: 'Tipo', value: 'type' },
        { text: 'Descrição', value: 'description' },
        { text: 'Valor produto', value: 'productValue' },
        { text: 'Valor Acréscimo', value: 'interestValue' },
        { text: 'Valor frete', value: 'shippingValue' },
        { text: 'Valor comissão', value: 'commissionValue' },
        { text: 'Valor líquido', value: 'netValue' },
        { text: 'Situação fatura', value: 'invoiceStatus' },
      ],
    };
  },
  methods: {
    getStatusName,
    openDetail(id) {
      routerPush(`/invoice/detail/${id}`);
    },
    showManualInvoiceModal() {
      this.showModal = true;
    },
    cancelManualInvoiceModal() {
      this.showModal = false;
    },
    closeManualInvoiceModal() {
      this.showModal = false;
      this.getInvoice();
      this.getInvoiceTotal();
    },
    navigatorToInvoice(id) {
      if (!id) return;
      routerPush(`/invoice/detail/${id}`);
    },
    async getInvoice() {
      try {
        const { itemsPerPage, page } = this.optionsOrder;
        const response = await getInvoice(this.$route.params.number, itemsPerPage, page - 1);
        this.itemsOrder = response.register;
        this.formatOrder();
        this.totalElementsOrder = response.totalRegister;
      } catch (error) {
        notification('Erro ao recuperar os dados da fatura', 'error');
        console.error('Payment.getInvoice', error);
      }
    },
    async getInvoiceTotal() {
      try {
        const response = await getInvoiceTotal(this.$route.params.number);
        this.form = response;
      } catch (error) {
        notification('Erro ao recuperar os dados da fatura', 'error');
        console.error('Payment.getInvoiceTotal', error);
      }
    },
    formatOrder() {
      this.itemsOrder = this.itemsOrder.map((item) => ({
        ...item,
        description: item.installments > 1 ? `${item.description} ${item.installment}/${item.installments}` : `${item.description}`,
        dateAndHour: item.dateAndHour.split(' ', 1),
      }));
    },
    getColor(commissionValue) {
      if (commissionValue) return 'color--green-0';
      return 'color--grey-1';
    },
    getColorStatus(status) {
      if (status === statusEnum.PAGA) return 'color--green-0';
      return 'color--orange-1';
    },
  },
});
</script>

<style scoped lang="scss">
.composition {
  &-description {
    background: var(--background-2);
    border-bottom: 1px solid var(--grey-3);
    font-size: 14px;
    align-items: center;
    padding: 15px;
    border-radius: 4px;
  }
  &-total {
    background: var(--background-2);
    border-bottom: 1px solid var(--grey-3);
    font-size: 18px;
    align-items: center;
  }
}

.col-description {
  width: 50%;
  text-align: start;

  &--total {
    width: 50%;
    font-size: 18px;
    text-align: start;
  }
}

.col-value {
  width: 50%;
  font-size: 22px;
  text-align: end;

  &--total {
    width: 50%;
    color: var(--blue-0);
    font-size: 28px;
    text-align: end;
  }
}

.table-color {
  color: inherit;
}
</style>

<template>
  <FormContent :selected="selected" class="pa-6">
    <div class="margin-fix pa-3 mb-10">
      <h3>{{ title }}</h3>
    </div>
    <v-data-table
      :headers="header"
      :items="items"
      class="elevation-1 custom-table mb-8"
      multi-sort
      :server-items-length="items.length"
      :footer-props="{
        'items-length': 100,
        'items-per-page-text': 'Linhas por página',
        'items-per-page-options': [10, 20, 50],
        pageText: '{0}-{1} de {2}',
      }"
    >
      <template v-slot:[`item.quantity`]="{ item }">
        <v-chip class="itens">{{ item.quantity }}</v-chip>
      </template>
      <template v-slot:[`item.unitPrice`]="{ item }">
        R$ {{ Number(item.unitPrice).toLocaleString('pt-br', { minimumFractionDigits: 2 }) }}
      </template>
      <template v-slot:[`item.totalPrice`]="{ item }">
        R$ {{ Number(item.totalPrice).toLocaleString('pt-br', { minimumFractionDigits: 2 }) }}
      </template>
      <template v-slot:[`item.hasServiceToExecute`]="{ item }">
        <v-icon v-if="item.hasServiceToExecute" color="var(--green-0)">mdi-check</v-icon>
        <v-icon v-else color="var(--red-0)">mdi-close</v-icon>
      </template>
      <template v-slot:no-data>
        <div class="pt-10 pb-10">
          Nenhum resultado encontrado
        </div>
      </template>
    </v-data-table>
    <div class="d-flex">
      <label class="left-content">Total do frete</label>
      <mask-input v-model="freightDetails.cost" :options="MaskOptions" :disabled="true" dense filled />
      <label class="left-content ml-10">Total do pedido</label>
      <mask-input v-model="order.total" :options="MaskOptions" :disabled="true" dense filled />
    </div>
  </FormContent>
</template>

<script>
import Vue from 'vue';
import FormContent from '@/components/template/form/FormContent';
import MaskInput from '@/components/template/form/input/MaskInput';

const MaskOptions = {
  numeral: true,
  numeralDecimalScale: 2,
  numeralDecimalMark: ',',
  delimiter: '.',
  prefix: 'R$ ',
};

export default Vue.extend({
  components: {
    FormContent,
    MaskInput,
  },

  props: {
    title: {
      type: String,
      required: false,
      default: 'Itens e Serviços',
    },
    items: {
      type: Array,
      required: true,
    },
    order: {
      type: Object,
      required: true,
    },
    freightDetails: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    selected: false,
    header: [
      { text: 'Código', value: 'platformId' },
      { text: 'Itens', value: 'description' },
      { text: 'SKU', value: 'skuId' },
      { text: 'Valor Unitário', value: 'unitPrice' },
      { text: 'Quantidade', value: 'quantity', align: 'center' },
      { text: 'Valor total', value: 'totalPrice' },
      { text: 'Serviço', value: 'hasServiceToExecute', align: 'center' },
    ],
    MaskOptions,
  }),
});
</script>

<style lang="scss" scoped>
.itens.theme--light.v-chip {
  color: white;
  background: #f44fe3;
}
</style>

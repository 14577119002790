<template>
  <CustomModal :showModal="showModal" maxWidth="850" :persistent="true">
    <template v-slot:header>
      <div class="color--grey-0">
        <div class="mb-10 custom-form-font-title">
          Detalhes do pacote de entrega
        </div>
      </div>
    </template>
    <template v-slot:content>
      <div class="d-flex flex-column">
        <div class="d-flex pb-5">
          <label class="left-content ">ID do pacote de entrega</label>
          <label class="itens">{{ form.id }}</label>
          <label class="left-content ">Método de entrega</label>
          <label class="itens">{{ form.shippingMethod }}</label>
        </div>
        <div class="d-flex pb-5">
          <label class="left-content">Status do pacote</label>
          <label class="itens">{{ statusOrder[form.status] || form.status }}</label>
          <label class="left-content">Quantidade de itens</label>
          <label class="itens">{{ form.totalProducts || 0 }}</label>
        </div>
        <div class="d-flex pb-5">
          <label class="left-content-unique">Observações</label>
          <label class="itens">{{ form.description }}</label>
        </div>
        <div class="d-flex pb-5">
          <label class="left-content">Valor bruto do envio</label>
          <label class="itens">R$ {{ amount || '0,00' }}</label>
          <label class="left-content">Taxa de envio</label>
          <label class="itens right-content">R$ {{ shippingTax || '0,00' }}</label>
        </div>
        <div class="d-flex pb-5 mb-10">
          <label class="left-content-unique">Endereço de envio</label>
          <label class="itens">{{ street }}</label>
        </div>

        <div class="mb-10 custom-form-font-title" v-if="separatedAt">
          Separação do pacote
          <div class="d-flex pt-5">
            <label class="left-content-unique">Data de Separação</label>
            <label class="itens">{{ separatedAt || '-' }}</label>
          </div>
        </div>

        <div class="mb-10 custom-form-font-title" v-if="url || number">
          Rastreamento
          <div class="d-flex pt-5">
            <label class="left-content-unique">Url de rastreamento</label>
            <label class="itens">
              <a v-bind:href="url" target="_blank">{{ url || '-' }}</a></label
            >
          </div>
          <div class="d-flex pt-5">
            <label class="left-content-unique">Código de rastreamento</label>
            <label class="itens">{{ number || '-' }}</label>
          </div>
          <div class="d-flex pt-5">
            <label class="left-content">CNPJ da Transpordadora</label>
            <label class="itens">{{ carrierDocument || '-' }}</label>
            <label class="left-content">Nome da transportadora</label>
            <label class="itens">{{ carrier || '-' }}</label>
          </div>
          <div class="d-flex pt-5">
            <label class="left-content">Data de despacho</label>
            <label class="itens">{{ shippedDate || '-' }}</label>
            <label class="left-content">Data estimada de entrega</label>
            <label class="itens">{{ estimateDate || '-' }}</label>
          </div>
        </div>

        <div class="mb-10 custom-form-font-title" v-if="receiver">
          Entrega do pacote
          <div class="d-flex pt-5">
            <label class="left-content-unique">Nome do recebedor</label>
            <label class="itens">{{ receiver || '-' }}</label>
          </div>
          <div class="d-flex pt-5">
            <label class="left-content">Documento do recebedor</label>
            <label class="itens">{{ document || '-' }}</label>
            <label class="left-content">Data de entrega</label>
            <label class="itens">{{ date || '-' }}</label>
          </div>
        </div>

        <div class="mb-10 custom-form-font-title" v-if="marketplaceId">
          Retira Loja
          <div class="d-flex pt-5">
            <label class="left-content">ID do Protocolo</label>
            <label class="itens">{{ marketplaceId || '-' }}</label>
            <label class="left-content">ID da loja de retirada</label>
            <label class="itens">{{ storeId || '-' }}</label>
          </div>
          <div class="d-flex pt-5">
            <label class="left-content-unique">Observações</label>
            <label class="itens">{{ description || '-' }}</label>
          </div>
          <div class="d-flex pt-5">
            <label class="left-content-unique">Nome do recebedor</label>
            <label class="itens">{{ receiverName || '-' }}</label>
          </div>
          <div class="d-flex pt-5">
            <label class="left-content">Documento do recebedor</label>
            <label class="itens">{{ receiverDocument || '-' }}</label>
            <label class="left-content">Data para retirada</label>
            <label class="itens">{{ availableAt || '-' }}</label>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="d-flex flex-grow-1 justify-end">
        <v-btn outlined class="mx-5" color="primary" @click="closeModal()">FECHAR</v-btn>
      </div>
    </template>
  </CustomModal>
</template>

<script>
import moment from 'moment';
import CustomModal from '@/components/modal/CustomModal';
import { statusOrder } from '../enum/StatusOrder';

export default {
  data() {
    return {
      statusOrder,
      amount: null,
      shippingTax: null,
      street: null,
      separatedAt: null,
      marketplaceId: null,
      storeId: null,
      description: null,
      receiverName: null,
      receiverDocument: null,
      availableAt: null,
      receiver: null,
      document: null,
      date: null,
      url: null,
      number: null,
      carrierDocument: null,
      carrier: null,
      shippedDate: null,
      estimateDate: null,
    };
  },
  components: {
    CustomModal,
  },
  watch: {
    form: {
      handler(newvalue) {
        this.initShippingPrice(newvalue);
      },
      deep: true,
    },
  },
  props: {
    showModal: { type: Boolean, default: false },
    close: { type: Function },
    form: { type: Object },
  },
  methods: {
    closeModal() {
      this.$props.close();
    },
    formatMoment(value) {
      if (value) return moment(value).format('DD/MM/YYYY HH:MM');
      return null;
    },
    getStreet() {
      if (this.form?.shippingAddress?.street) {
        const { street, number, complement, neighborhood, city, state, zipcode } = this.form.shippingAddress;
        const filteredArray = [
          `${street},`,
          number && ` ${number}`,
          complement && ` - ${complement}`,
          neighborhood && ` - ${neighborhood}`,
          city && ` - ${city}`,
          state && ` - ${state}`,
          zipcode && ` - CEP ${zipcode}`,
        ].filter(Boolean);

        return filteredArray.join('');
      }
      return '-';
    },
    initShippingPrice(newvalue) {
      // Detalhes do pacote de entrega
      this.amount = newvalue?.shippingPriceInfo?.amount.replace('.', ',') || '0,00';
      this.shippingTax = newvalue?.shippingPriceInfo?.shippingTax.replace('.', ',') || '0,00';
      this.street = this.getStreet.call(this);
      // Separação do pacote
      this.separatedAt = this.formatMoment(newvalue?.separatedAt);
      // Rastreamento
      this.url = newvalue.trackingDelivery?.url;
      this.number = newvalue.trackingDelivery?.number;
      this.carrierDocument = newvalue.trackingDelivery?.carrierDocument;
      this.carrier = newvalue.trackingDelivery?.carrier;
      this.shippedDate = this.formatMoment(newvalue?.trackingDelivery?.shippedDate);
      this.estimateDate = this.formatMoment(newvalue?.trackingDelivery?.estimateDate);
      //  Entrega do pacote
      this.receiver = newvalue?.deliveryInfo?.receiver;
      this.document = newvalue?.deliveryInfo?.document;
      this.date = this.formatMoment(newvalue?.deliveryInfo?.date);
      //  Retira Loja
      this.marketplaceId = newvalue?.storePickupInfo?.id;
      this.storeId = newvalue?.storePickupInfo?.storeId;
      this.description = newvalue?.storePickupInfo?.description;
      this.receiverName = newvalue?.storePickupInfo?.receiverName;
      this.receiverDocument = newvalue?.storePickupInfo?.receiverDocument;
      this.availableAt = this.formatMoment(newvalue?.storePickupInfo?.availableAt);
    },
  },
};
</script>

<style lang="scss" scoped>
.itens {
  font-family: $font-family-book;
  font-size: 14px;
  color: var(--grey-0);
  flex-grow: 1;
  width: 40%;
  background-color: var(--background-2);
  padding: 15px;
  margin-right: 10px;
  border-radius: 4px;
}

.left-content {
  width: 22%;
  color: var(--black-2);
}

.left-content-unique {
  width: 126px;
  color: var(--black-2);
}
</style>

<template>
  <CustomModal :showModal="showModal" maxWidth="850">
    <template v-slot:header>
      <div class="color--grey-0">
        <div class="mb-5 custom-form-font-title">
          Inclusão de lançamento de forma manual
        </div>
        <div class="mb-3 custom-form-font-subtitle">
          Observação: A inclusão de um lançamento de valor positivo no pedido resultará em um crédito no valor líquido que será pago ao seller,
          enquanto um valor negativo resultará em um desconto no pagamento.
        </div>
        <div class="mb-10 custom-form-font-subtitle">
          O crédito ou desconto será efetivado na última fatura em aberto do seller.
        </div>
      </div>
    </template>
    <template v-slot:content>
      <div class="d-flex flex-column">
        <v-form v-model="formValidate">
          <div class="d-flex">
            <label class="custom-form-font left-content color--black-2">Tipo do lançamento*</label>
            <v-combobox
              class="custom-form-font-object color--grey-1"
              placeholder="Selecione"
              item-text="name"
              item-value="code"
              :items="options"
              :rules="[rules.required]"
              v-model="type"
              filled
            />
            <label class="custom-form-font left-content component-value">Valor (R$)*</label>
            <mask-input
              class="custom-form-font-object color--grey-1"
              placeholder="Digite o valor"
              v-model="form.value"
              :rules="[rules.required, rules.fullyFilled]"
              filled
            />
          </div>
          <div class="d-flex">
            <label class="custom-form-font left-content color--black-2">Descrição*</label>
            <v-textarea
              class="custom-form-font-object right-content color--grey-1"
              placeholder="Digite uma descrição para o lançamento"
              v-model="form.description"
              :rules="[rules.required, rules.length(5000)]"
              counter="5000"
              filled
              dense
              light
            />
          </div>
        </v-form>
      </div>
    </template>
    <template v-slot:footer>
      <div class="d-flex flex-grow-1 justify-end">
        <v-btn outlined class="mx-5" color="primary" @click="cancelModal()">CANCELAR</v-btn>
        <v-btn class="mx-2" color="primary" :disabled="!formValidate" @click="savePayment(form)">INCLUIR LANÇAMENTO</v-btn>
      </div>
    </template>
  </CustomModal>
</template>

<script>
import CustomModal from '@/components/modal/CustomModal';
import { saveSellerCommissionedItem } from '@/service/invoice/invoice-service';
import notification from '@/service/notification';
import MaskInput from '@/components/template/form/input/MaskInput';

export default {
  data() {
    return {
      teste: 0,
      form: {
        type: null,
        value: null,
        description: null,
        orderNumber: null,
      },
      formValidate: false,
      type: null,
      options: [
        {
          name: 'Crédito no pagamento',
          code: 'CREDIT',
        },
        {
          name: 'Desconto no pagamento',
          code: 'DEBIT',
        },
      ],
      rules: {
        length: (len) => (v) => (v || '').length <= len || `Você excedeu o limite de caracteres disponíveis na mensagem ${len}`,
        required: (v) => !!v || 'Campo obrigatório',
        // eslint-disable-next-line consistent-return
        fullyFilled: (v) => {
          const trailingZerosRegex = /,(\d{2})$/;
          if (!trailingZerosRegex.test(v)) {
            return 'Preencha as casas decimais ex: R$ 123,00';
          }
          return true;
        },
      },
    };
  },
  components: {
    CustomModal,
    MaskInput,
  },
  props: {
    showModal: { type: Boolean, default: false },
    close: { type: Function },
    cancel: { type: Function },
    save: { type: Function },
    invoiceId: { type: String },
  },

  methods: {
    cancelModal() {
      this.$props.cancel();
      this.form = {};
    },
    closeModal() {
      this.$props.close();
      this.form = {};
    },
    async savePayment(form) {
      try {
        form.type = this.type.code;
        form.orderNumber = this.$route.params.number;
        const resp = await saveSellerCommissionedItem(form);
        if (resp.status === 200 || resp.status === 204) {
          this.closeModal();
          notification('O lançamento manual foi incluído no pedido com sucesso.', 'success');
        } else {
          notification(`Erro: ${resp.response.data.message}`, 'error');
        }
      } catch (error) {
        notification(`Não foi possível o lançamento manual.${error}`, 'error');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.component-value {
  text-align: right;
  padding-top: 15px;
  padding-right: 10px;
  color: var(--black-2);
}
</style>

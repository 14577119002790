<template>
  <FormContent :selected="selected" class="pa-6">
    <h3>Detalhes do Cliente</h3>
    <div class="d-flex">
      <label class="left-content-unique">Tipo</label>
      <v-text-field class="itens" :disabled="true" v-model="customer.personNameType" filled dense light />
    </div>
    <div class="d-flex">
      <label class="left-content-unique">Nome do Cliente</label>
      <v-text-field class="itens" :disabled="true" v-model="customer.name" filled dense light />
    </div>
    <div v-if="customer.personType === 'F'">
      <div class="d-flex">
        <label class="left-content">Data de nascimento</label>
        <v-text-field class="itens" :disabled="true" v-model="customer.birthday" filled dense light />
        <label class="left-content">Gênero</label>
        <v-text-field class="itens" :disabled="true" v-model="customer.gender" filled dense light />
      </div>
    </div>
    <div class="d-flex">
      <label class="left-content">Tipo do documento</label>
      <v-text-field class="itens" :disabled="true" v-model="customer.typeDocument" filled dense light />
      <label class="left-content">Número do documento</label>
      <v-text-field class="itens" :disabled="true" v-model="customer.document" filled dense light />
    </div>
    <div class="d-flex" v-if="customer.personType === 'J'">
      <label class="left-content-unique">Inscrição Estadual</label>
      <v-text-field class="itens" :disabled="true" v-model="customer.stateRegistration" filled dense light />
    </div>
    <div class="d-flex">
      <label class="left-content-unique">E-mail</label>
      <v-text-field class="itens" :disabled="true" v-model="customer.email" filled dense light />
    </div>
    <div class="d-flex">
      <label class="left-content">Telefone</label>
      <v-text-field class="itens" :disabled="true" v-model="customer.phone" filled dense light />
      <label class="left-content">Celular</label>
      <v-text-field class="itens" :disabled="true" v-model="customer.cellPhone" filled dense light />
    </div>
  </FormContent>
</template>

<script>
import Vue from 'vue';
import FormContent from '@/components/template/form/FormContent';

export default Vue.extend({
  components: {
    FormContent,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: 'Cliente',
    },
    customer: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    selected: false,
    colLabelWidth: '200px',
    colLabelHAlign: 'left',
  }),
});
</script>

<style lang="scss" scoped>
.itens {
  font-family: $font-family-book;
  font-size: 14px;
  color: var(--grey-0);
  flex-grow: 1;
  width: 40%;
  padding: 15px;
  margin-right: 10px;
  border-radius: 4px;
}

.left-content {
  width: 22%;
  color: var(--black-2);
  margin-top: 20px;
}

.left-content-unique {
  width: 17.5%;
  color: var(--black-2);
  margin-top: 20px;
}
</style>

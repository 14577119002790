<template>
  <FormContent :selected="selected" class="pa-6">
    <v-data-table
      :headers="header"
      :items="items"
      class="elevation-1 custom-table mb-8"
      :options.sync="options"
      :server-items-length="items.length"
      :hide-default-footer="true"
    >
      <template v-slot:[`item.actions`]="{ item }">
          <v-icon color="primary" @click="openDetail(item)">mdi-magnify-plus-outline</v-icon>
      </template>
      <template v-slot:no-data>
        <div class="pt-10 pb-10">
          Nenhum resultado encontrado
        </div>
      </template>
    </v-data-table>
    <FiscalNoteModal :showModal="showModal" :close="closeModal" :form="form" />
  </FormContent>
</template>

<script>
import Vue from 'vue';
import FormContent from '@/components/template/form/FormContent';
import FiscalNoteModal from './FiscalNoteModal';

export default Vue.extend({
  components: {
    FormContent,
    FiscalNoteModal,
  },

  props: {
    title: {
      type: String,
      required: false,
      default: 'Notas Fiscais',
    },
    items: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    selected: false,
    options: {},
    showModal: false,
    form: {},
    header: [
      { text: 'Chave acesso', align: 'start', value: 'accessKey' },
      { text: 'Nº Nota fiscal', value: 'number' },
      { text: 'Data emissão', value: 'date' },
      { text: 'Ação', value: 'actions' },
    ],
  }),
  methods: {
    openDetail(item) {
      this.showModal = true;
      this.form = item;
    },
    closeModal() {
      this.showModal = false;
      this.form = {};
    },
  },
});
</script>

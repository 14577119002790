export const PaymentStatusOption = [
  { key: 'paid', value: 'Pagamento confirmado' },
  { key: 'processing', value: 'Aguardando pagamento' },
  { key: 'rejected', value: 'Pagamento rejeitado' },
  { key: 'cancelled', value: 'Cancelado' },
  { key: 'canceled', value: 'Cancelado' },
  { key: 'with_error', value: 'Com erro' },
  { key: 'unknown', value: 'Desconhecido' },
];

export default PaymentStatusOption;

<template>
  <CustomModal :showModal="showModal" maxWidth="850" persistent>
    <template v-slot:header>
      <div class="color--grey-0">
        <div class="mb-10 mt-5 custom-form-font-title">
          Detalhes da nota fiscal
        </div>
      </div>
    </template>
    <template v-slot:content>
      <div class="d-flex flex-column">
        <div class="d-flex pb-5 mb-5">
          <label class="left-content ">Número/ Série</label>
          <label class="itens">{{ form.number }}/ {{ form.series }}</label>
          <label class="left-content ">Data de emissão</label>
          <label class="itens">{{ form.date|| '-' }}</label>
        </div>
        <div class="d-flex pb-5 mb-5">
          <label class="left-content-unique">Chave de acesso</label>
          <label class="itens">{{ form.accessKey|| '-' }}</label>
        </div>
        <div class="d-flex pb-5 mb-5">
          <label class="left-content-unique">URL para consulta</label>
          <label class="itens">{{ form.linkNfe|| '-' }}</label>
        </div>

      </div>
    </template>
    <template v-slot:footer>
      <div class="d-flex flex-grow-1 justify-end">
        <v-btn class="mx-5" color="primary" @click="closeModal()">FECHAR</v-btn>
      </div>
    </template>
  </CustomModal>
</template>

<script>
import CustomModal from '@/components/modal/CustomModal';

export default {
  components: {
    CustomModal,
  },
  props: {
    showModal: { type: Boolean, default: false },
    close: { type: Function },
    form: { type: Object },
  },
  methods: {
    closeModal() {
      this.$props.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.itens {
  font-family: $font-family-book;
  font-size: 14px;
  color: var(--grey-0);
  flex-grow: 1;
  width: 40%;
  background-color: var(--background-2);
  padding: 15px;
  margin-right: 10px;
  border-radius: 4px;
}

.left-content {
  width: 22%;
  color: var(--black-2);
}

.left-content-unique {
  width: 126px;
  color: var(--black-2);
}
</style>

export const PaymentTypeOptions = [
  { key: 'undefined', value: 'Indefinido' },
  { key: 'card', value: 'Cartão de Crédito' },
  { key: 'debit_card', value: 'Cartão de Débito' },
  { key: 'pix', value: 'Pix' },
  { key: 'boleto', value: 'Boleto' },
  { key: 'store_credit', value: 'Crédito em Loja' },
  { key: 'payment_store', value: 'Pagamento em Loja' },
  { key: 'konta', value: 'Konta' },
  { key: 'mercado_pago', value: 'Mercado Pago' },
  { key: 'kab-authorization', value: 'Autorização KAB' },
  { key: 'outros', value: 'Outros' },
];

export default PaymentTypeOptions;

<template>
  <v-container class="box-content pa-0 pl-4 pr-4 pt-4" fluid>
    <FormStepController
      :showActionButtons="false"
      :order="order"
      title="Detalhes do Pedido"
      :attendanceButton="isEditing"
      :onClickAttendanceHandler="onClickAttendanceHandler"
    >
      <GeneralData :order="order.general" />
      <Client :customer="order.customer" />
      <Freight :freightDetails="order.freightDetails" />
      <ItemService :items="order.items" :order="order.general" :freightDetails="order.freightDetails" />
      <Payment :items="order.payments" />
      <FiscalNote :items="order.fiscalNote" />
      <ShippingPackages :shippingPackages="order.shippingPackages" :shippingPackagesTotals="order.shippingPackagesTotals" />
      <template v-slot:endActions>
        <v-btn v-show="isEditing" class="ml-4 form_step_controller_action_button" color="primary" outlined large @click="onClickAttendanceHandler">
          SOLICITAR ATENDIMENTO
        </v-btn>
      </template>
    </FormStepController>
    <custom-modal-template
      :show="showAttendanceConfirmationDialog"
      :title="'Abertura de chamado'"
      :description="`Você deseja abrir um chamado para o Pedido ${form.number} ?`"
      :actionHandler="attendanceModalActionHandler"
    />
  </v-container>
</template>

<script>
import moment from 'moment';
import utils from '@/utils/utils';
import FormStepController from '@/components/template/form/FormStepController';
import GeneralData from '@/views/order/detail/GeneralData';
import Client from '@/views/order/detail/Client';
import { get } from '../../../service/api';
import notification from '@/service/notification';
import { formatDateWithoutHour } from '@/utils/format-utils';
import { getSeller } from '@/service/seller/seller-service';
import { OrderStatus } from '@/static-data/OrderStatusOptions';
import Freight from '@/views/order/detail/Freight';
import ItemService from '@/views/order/detail/ItemService';
import Payment from '@/views/order/detail/Payment';
import FiscalNote from '@/views/order/detail/FiscalNote';
import { PaymentStatusOption } from '@/static-data/PaymentStatusOptions';
import { PaymentTypeOptions } from '@/static-data/PaymentTypeOptions';
import { PersonTypeOptions, PersonDocumentTypeOptions } from '@/static-data/PersonTypeOptions';
import ShippingPackages from '@/views/order/detail/ShippingPackage';
import CustomModalTemplate from '../components/CustomModalTemplate';
import { GenderTypeOptions } from '@/static-data/GenderTypeOptions';

export default {
  name: 'OrderDetail',
  data: () => ({
    showAttendanceConfirmationDialog: false,
    paymentStatusOption: PaymentStatusOption,
    paymentTypeOptions: PaymentTypeOptions,
    GenderTypeOptions,
    isEditing: null,
    status: OrderStatus,
    form: {},
    order: {
      general: {},
      customer: {},
      freightDetails: {},
      items: [],
      payments: [],
      shippingPackages: [],
      shippingPackagesTotals: {
        uniqueProducts: '',
        totalProducts: '',
      },
      fiscalNote: [],
    },
  }),
  components: {
    FiscalNote,
    ShippingPackages,
    Payment,
    ItemService,
    Freight,
    FormStepController,
    GeneralData,
    Client,
    CustomModalTemplate,
  },
  mounted() {
    try {
      if (this.$route.params.number) {
        this.gerOrder(this.$route.params.number);
        this.isEditing = true;
      }
    } catch (error) {
      this.isEditing = false;
      console.error('Error on mount SellerForm trying to get order', error);
    }
  },
  methods: {
    onClickAttendanceHandler() {
      this.showAttendanceConfirmationDialog = true;
    },
    setGeneralData(response) {
      this.order.general = {
        number: response.number,
        status: utils.getValueFromKey(this.status, response.status),
        total: Number(response.total).toFixed(2),
        sellerId: this.retrieveSellerName(response.sellerId),
        synchronizedAt: response.synchronizedAt,
      };
    },
    setClient(response) {
      const { customer } = response;
      try {
        this.order.customer = customer;
        this.order.customer = {
          ...this.order.customer,
          name: `${customer.firstName} ${customer.lastName}` || customer.companyName,
          birthday: customer.birthday ? formatDateWithoutHour(customer.birthday) : null,
          gender: customer.gender ? GenderTypeOptions[customer.gender.toLowerCase()] : null,
          personNameType: customer.personType ? PersonTypeOptions[customer.personType.toUpperCase()] : null,
          typeDocument: customer.personType ? PersonDocumentTypeOptions[customer.personType.toUpperCase()] : null,
        };
      } catch (e) {
        console.error('setClient', e);
      }
    },
    setFreight(response) {
      try {
        const total = response.freight - response.discount;
        this.order.freightDetails = {
          cost: response.freight.toLocaleString('pt-br', { minimumFractionDigits: 2 }),
          totalValue: total.toLocaleString('pt-br', { minimumFractionDigits: 2 }),
          discount: response.discount.toLocaleString('pt-br', { minimumFractionDigits: 2 }),
          status: utils.getValueFromKey(this.status, response.status),
          tax: response?.tax?.toLocaleString('pt-br', { minimumFractionDigits: 2 }) || '0,00',
        };
      } catch (e) {
        console.error('setFreight', e);
      }
    },
    setItemServices(response) {
      this.order.items = response.items;
    },
    setPayments(response) {
      try {
        response.payments.forEach((payment) => {
          const type = payment.type ? utils.getValueFromKey(this.paymentTypeOptions, payment.type.toLowerCase()) : 'Outros';
          payment.type = type || 'Outros';
          const status = payment.status ? utils.getValueFromKey(this.paymentStatusOption, payment.status.toLowerCase()) || payment.status : '';
          payment.status = status || 'Desconhecido';
          payment.paidAt = payment.paidAt ? formatDateWithoutHour(payment.paidAt) : '';
        });
        this.order.payments = response.payments;
      } catch (e) {
        console.error('setPayments', e);
      }
    },
    setShippingPackages(response) {
      try {
        this.order.shippingPackages = response.shippingPackages;
        response.shippingPackages.forEach((shippingPkg) => {
          shippingPkg.status = utils.getValueFromKey(this.status, shippingPkg.status);
          shippingPkg.uniqueProducts = shippingPkg.itemRelationships.length;
          shippingPkg.totalProducts = this.getTotalProducts(shippingPkg.itemRelationships);
          shippingPkg.separationDate = this.getSeparationDate(shippingPkg.separatedAt);
        });
      } catch (e) {
        console.error('setShippingPackages', e);
      }
    },
    setFiscalNote(response) {
      this.order.fiscalNote = [...response.shippingPackages
            .filter((pacote) => pacote.invoiceInfo !== null)
            .map((pacote) => pacote.invoiceInfo)];
    },
    getSeparationDate(separationInfo) {
      if (separationInfo === null) {
        return 'Não se aplica';
      }
      return moment(separationInfo).format('DD/MM/YYYY');
    },
    getTotalProducts(productsInPackage) {
      let totalItens = 0;
      productsInPackage.forEach((product) => {
        totalItens += product.quantity;
      });
      return totalItens;
    },
    retrieveSellerName(id) {
      getSeller(id)
        .then((response) => {
          this.order.general.sellerId = response.companyName;
        })
        .catch((error) => {
          notification('Erro ao buscar nome do Seller', 'error');
          return id;
        });
    },
    formatOrderDetails(response) {
      this.setGeneralData(response);
      if (response.customer) this.setClient(response);
      if (response.freight) this.setFreight(response);
      if (response.items) this.setItemServices(response);
      if (response.payments) this.setPayments(response);
      if (response.shippingPackages) this.setShippingPackages(response);
      this.setFiscalNote(response);
    },
    async gerOrder(id) {
      try {
        const response = await get(`ui-integrator/orders/${id}`);
        this.form = {
          ...response.data,
        };
        this.formatOrderDetails(this.form);
      } catch (e) {
        notification('Erro ao recuperar o pedido', 'error');
      }
    },
    attendanceModalActionHandler(action) {
      this.showAttendanceConfirmationDialog = false;
      if (action) {
        this.$router.push({ path: `/attendance/forms/order/${this.form?.number}` });
      }
    },
  },
};
</script>

<style scoped>
.box-content {
  background-color: rgb(237, 237, 237);
  height: calc(100% - 80px);
  margin-top: 80px;
}
</style>

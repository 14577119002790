var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FormContent',{staticClass:"pa-6",attrs:{"selected":_vm.selected}},[_c('v-row',{staticClass:"margin-fix pa-3 mb-10"},[_c('h3',[_vm._v("Detalhes de Pacotes de Entrega")])]),_c('v-data-table',{staticClass:"elevation-1 custom-table mb-8",attrs:{"headers":_vm.header,"items":_vm.shippingPackages,"options":_vm.options,"multi-sort":"","server-items-length":_vm.shippingPackages.length,"hide-default-footer":true},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.totalProducts",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"itens"},[_vm._v(_vm._s(item.totalProducts))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"camelcase"},[_vm._v(_vm._s(_vm.statusOrder[item.status]))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":20,"color":"primary"},on:{"click":function () { return _vm.showDetailModal(item); }}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s('mdi-magnify-plus-outline')+" ")])]}}],null,true)},[_vm._v(" "+_vm._s('Visualizar')+" ")])]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"pt-10 pb-10"},[_vm._v(" Nenhum resultado encontrado ")])]},proxy:true}],null,true)}),_c('ShippingPackageModal',{attrs:{"showModal":_vm.showModal,"close":_vm.closeModal,"form":_vm.form}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
export const OrderStatus = [
  { key: 'aguardando_pagamento', value: 'Aguardando pagamento' },
  { key: 'pedido_recebido', value: 'Pedido recebido' },
  { key: 'pagamento_confirmado', value: 'Pagamento confirmado' },
  { key: 'reservado', value: 'Reservado' },
  { key: 'faturado', value: 'Faturado' },
  { key: 'em_separacao', value: 'Em separação' },
  { key: 'retirada_disponivel', value: 'Retirada disponível' },
  { key: 'transporte', value: 'Transporte' },
  { key: 'entrega_realizada', value: 'Entrega realizada' },
  { key: 'aguardando_execucao_servico', value: 'Aguardando execução de serviço' },
  { key: 'servico_executado', value: 'Serviço executado' },
  { key: 'finalizado', value: 'Finalizado' },
  { key: 'cancelado', value: 'Cancelado' },
  { key: 'pedido_cancelado_manual', value: 'Cancelado manualmente' },
  { key: 'pedido_cancelado_ncredit', value: 'Cancelado pelo cartão de crédito' },
  { key: 'pedido_cancelado_pix', value: 'Cancelado via pix' },
];

export default OrderStatus;

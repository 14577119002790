<template>
  <div>
    <AdminOrderForm v-if="$store.getters.isAdmin"></AdminOrderForm>
    <SellerForm v-else></SellerForm>
  </div>
</template>

<script>
import AdminOrderForm from './admin/AdminOrderForm';
import SellerForm from './seller/SellerForm';

export default {
  name: 'Form',
  components: { SellerForm, AdminOrderForm },
};
</script>

<style scoped></style>

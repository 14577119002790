<template>
  <FormContent :selected="selected" class="pa-6">
    <v-row class="margin-fix pa-3 mb-10">
      <h3>Detalhes de Pacotes de Entrega</h3>
    </v-row>
    <v-data-table
      :headers="header"
      :items="shippingPackages"
      class="elevation-1 custom-table mb-8"
      :options.sync="options"
      multi-sort
      :server-items-length="shippingPackages.length"
      :hide-default-footer="true"
    >
      <template v-slot:[`item.totalProducts`]="{ item }">
        <v-chip class="itens">{{ item.totalProducts }}</v-chip>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <div class="camelcase">{{ statusOrder[item.status] }}</div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon :size="20" @click="() => showDetailModal(item)" color="primary" v-bind="attrs" v-on="on">
              {{ 'mdi-magnify-plus-outline' }}
            </v-icon>
          </template>
          {{ 'Visualizar' }}
        </v-tooltip>
      </template>
      <template v-slot:no-data>
        <div class="pt-10 pb-10">
          Nenhum resultado encontrado
        </div>
      </template>
    </v-data-table>
    <ShippingPackageModal :showModal="showModal" :close="closeModal" :form="form" />
  </FormContent>
</template>

<script>
import Vue from 'vue';
import FormContent from '@/components/template/form/FormContent';
import ShippingPackageModal from './ShippingPackageModal';
import { statusOrder } from '../enum/StatusOrder';

export default Vue.extend({
  components: {
    FormContent,
    ShippingPackageModal,
  },

  props: {
    title: {
      type: String,
      required: false,
      default: 'Pacotes de Entrega',
    },
    shippingPackages: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    statusOrder,
    selected: false,
    options: {},
    showModal: false,
    form: {},
    header: [
      { text: 'ID do pacote', align: 'start', value: 'id' },
      { text: 'Método', value: 'shippingMethod' },
      { text: 'Descrição', value: 'description' },
      { text: 'Status', value: 'status' },
      { text: 'Itens', value: 'totalProducts' },
      { text: 'Separação', value: 'separationDate' },
      { text: 'Ação', value: 'actions' },
    ],
  }),
  methods: {
    showDetailModal(item) {
      this.form = item;
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
  },
});
</script>

<style lang="scss" scoped>
.itens.theme--light.v-chip {
  color: white;
  background: #f44fe3;
}
</style>
